import React from 'react'
import Text from "./types/text"
import "./index.css"
import Dropdown from './types/dropdown'
import Checkbox from './types/checkbox'

const Input = React.memo(props => {
    const { type, min, max, onEditComplete, onFocusChange, onChange, placeholder, post, options, def, key, label, auto, list, listMap, filter } = props
    switch(type){
        case 'select':
            return <Dropdown
                type={type}
                onEditComplete={onEditComplete}
                onFocusChange={onFocusChange}
                options={options}
                def={def}
                key={key}
            />
        case 'bool':
            return <Dropdown
                type={type}
                onEditComplete={onEditComplete}
                onFocusChange={onFocusChange}
                options={(options || ['Yes', 'No']).slice(0, 2).map((o, i) => { return { name: o, value: i%2==0 } })}
                def={def}
                key={key}
            />
        case 'checkbox':
            return <Checkbox
                onEditComplete={onEditComplete}
                onFocusChange={onFocusChange}
                def={def}
                key={key}
                label={label}
            />
        default:
            return <Text
                placeholder={placeholder}
                type={type}
                min={min}
                max={max}
                onEditComplete={onEditComplete}
                onFocusChange={onFocusChange}
                post={post}
                def={def}
                key={key}
                onChange={onChange}
                auto={auto}
                list={list}
                listMap={listMap}
                filter={filter}
            />
    }
})

export default Input

