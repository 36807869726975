import React from 'react'
import "./index.css"
import Header from '../../components/custom/header'
import { useGlobal } from '../../context/global'
import ConstructionPage from './homes/construction'
import FOLKMemberHome from './homes/folkmember'
import AppAdminHome from './homes/appadmin'
import HODHome from './homes/hod'

function Home() {
  const { auth } = useGlobal()
  const { user } = auth

  const HomeContent = () => {
    switch (user.roleID) {
      case "folk-member":
        return <FOLKMemberHome />
      case "app-admin":
        return <AppAdminHome />
      case "folk-hod":
        return <HODHome />
      default:
        return <ConstructionPage />
    }
  }

  return (
    <div className='app-page'>
      <Header />
      <div className='app-body flex-col flex-align-center'>
        <HomeContent />
      </div>
    </div>
  )
}


export default Home
