import Form from "../../components/custom/form"
import { getMemberConfig } from "../../data/member"
import "./index.css"


const SignUp = props => {


    const onSubmit = values => {
        console.log(values)
    }

    return (
        <div className="signup-main">
            <Form
                name="folk-signup"
                config={getMemberConfig()}
                paginate
                onSubmit={onSubmit}/> 
        </div>
    )
}

export default SignUp