import styled, { css } from "styled-components";

export const StyledIcon = styled.svg`
    ${props => css`
            width: ${props.size[0] || 7}vw;
            height: ${props.size[0] || 7}vw;

            @media screen and (min-width: 1024px) and (orientation: landscape) {
                width: ${props.size[1] || 3.5}vw;
                height: ${props.size[1] || 3.5}vw;
            }


        `
    }

`