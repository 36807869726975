import "./index.css"
import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, LineElement, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title } from 'chart.js'
import { useGlobal } from "../../../../context/global"
import _ from "../../../../_"

ChartJS.register(LineElement, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title)

const StrengthPie = ({ data }) => {

    const { strength, strengthDist } = data

    const plotData = {
        labels: strength.map(d => `${d.course} (${d.count})`),
        datasets: [
            {
                label: 'Strength',
                data: strength.map(d => d.count),
                backgroundColor: strength.map(d => _.courseColors[d.course]),
                borderColor: ['#fff', '#fff', '#fff', '#fff'],
                borderWidth: 1
            }
        ]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            tooltip: {
                enabled: true
            },
            title: {
                display: true,
                text: `Total FOLK Strength (${strength.reduce((a, c) => a + c.count, 0)})`,
                font: {
                    family: "'Domine', serif"
                }
            }
        },
        onClick: (event, element) => {
            console.log(event, element)
        }
    }

    return <Pie data={plotData} options={options} />
}

const RegularityPIe = ({ data }) => {

    data = data.customSort(_.regularitySeq, 'status')
    const plotData = {
        labels: data.map(d => `${d.status} (${d.count})`),
        datasets: [
            {
                label: 'Count',
                data: data.map(d => d.count),
                backgroundColor: data.map(d => _.statusColors[d.status]),
                borderColor: ['#fff', '#fff', '#fff', '#fff'],
                borderWidth: 1
            }
        ]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            tooltip: {
                enabled: true
            },
            title: {
                display: true,
                text: `FOLK Regularity (${data.reduce((a, c) => a + c.count, 0)})`,
                font: {
                    family: "'Domine', serif"
                }
            }
        },
        onClick: (event, element) => {
            console.log(event, element)
        }
    }

    return <Pie data={plotData} options={options} />
}


const AppAdminHome = props => {

    const { api, auth } = useGlobal()
    const { id, roleID } = auth.user

    const [strength, setStrength] = useState()
    const [strengthDist, setStrengthDist] = useState()
    const [regularity, setRegularity] = useState()

    useEffect(() => {
        api.call('/get-home-data', { id, roleID }).then(res => {
            setStrength(res.data[0])
            setStrengthDist(res.data[1])
            setRegularity(res.data[2])
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <div>
            {strength && regularity ?
                <div>
                    <StrengthPie data={{ strength, strengthDist }} />
                    <RegularityPIe data={regularity} />
                </div>
                : null}
        </div>

    )
}

export default AppAdminHome