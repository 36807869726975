import _ from "../../_";

export const dashboardCourseConfig = [
    {
        title: 'registrations',
        colors: _.courseColors
    },
    {
        title: 'participation',
        colors: _.courseColors
    },
    {
        title: 'progress',
        colors: _.progressColors
    }
]