import "./index.css"
import React, { useState, useEffect } from 'react'
import { useGlobal } from '../../../../context/global'
import _ from '../../../../_'
import Dropdown from '../../../../components/custom/input/types/dropdown'
import { dashboardCourseConfig } from '../../../../data/dashboard'
import { TrendGraph, getTrendGraphConfig } from "../../../../components/graphs/trend"

const HODHome = props => {

    const { api, auth } = useGlobal()
    const { id, roleID } = auth.user

    const [range, setRange] = useState(_.getPeriod(_.periods[0]))
    const [aggregationFreq, setAggregationFreq] = useState(_.aggregationFreqs[0])
    const [graphType, setGraphType] = useState(_.trendTypes[0])
    const [graphData, setGraphData] = useState()
    const [data, setData] = useState()

    useEffect(() => {
        api.call('/get-hod-home-data', { id, roleID }).then(({ data }) => {
            setData(data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        if (!data) {
            return
        }

        var temp = {}
        Object.keys(data).forEach(parameter => {
            temp[parameter] = data[parameter].map(({ courseID, milestoneID, data }) => {
                return {
                    courseID,
                    milestoneID,
                    data: _.aggregate(data, range[0], range[1], aggregationFreq, 'count', true)
                }
            })

        })
        setGraphData(temp)
    }, [data, range, aggregationFreq])

    const handlePeriodChange = period => {
        setRange(_.getPeriod(period))
    }



    return (
        <div className='hodhome-main'>

            <div className="hod-trend-main">

                <div className="hod-home-course">Courses</div>

                <div className="flex-row hodhome-params">
                    <div className="flex-row flex-align-center hodhome-param">
                        <div className="hod-home-label">Period</div>
                        <div className="relative">
                            <Dropdown options={_.periods} def={_.periods[0]} className="hodhome-dropdown" onChange={handlePeriodChange} />
                        </div>
                    </div>

                    <div className="flex-row flex-align-center hodhome-param">
                        <div className="hod-home-label">Aggregate</div>
                        <div className="relative">
                            <Dropdown options={_.aggregationFreqs} def={_.aggregationFreqs[0]} className="hodhome-dropdown" onChange={setAggregationFreq} />
                        </div>
                    </div>

                    <div className="flex-row flex-align-center hodhome-param">
                        <div className="hod-home-label">Graph Type</div>
                        <div className="relative">
                            <Dropdown options={_.trendTypes} def={_.trendTypes[0]} className="hodhome-dropdown" onChange={setGraphType} />
                        </div>
                    </div>

                </div>

                {
                    graphData ? <div className="hodhome-trend-cont">
                        {
                            dashboardCourseConfig.map(config => <TrendGraph config={getTrendGraphConfig(config, graphData[config.title])} type={graphType} className='hodhome-trend' />)
                        }
                    </div> : null
                }

            </div>

        </div>
    )
}

export default HODHome