import moment from 'moment'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^\d{10}$/

const coerceToArray = e => Array.isArray(e) ? e : [e]

const filterAccess = (a, u) => a.filter(b => (b.exact || Array.isArray(b.access)) ? coerceToArray(b.access).indexOf(u.roleIndex) != -1 : u.roleIndex <= (b.access || 99999))

const regularitySeq = ['Regular', 'Irregular', 'Inactive', 'New', 'Snooze']

const statusColors = {
    Regular: '#8BC34A',
    Irregular: '#FFC107',
    Inactive: '#FF5252',
    New: '#3F51B5',
    Snoozed: '#795548',
    NA: 'grey'
}

const courseColors = {
    'No Course': '#888',
    SOS: '#43A047',
    PTC: '#FF4081',
    FEC: '#7B1FA2'
}

const progressColors = {
    'SOS-0': '#3F51B5',
    'SOS-1': '#43A047',
    'PTC-0': '#FF4081',
    'FEC-0': '#7B1FA2'
}

const aggregationFreqs = ['Weekly', 'Monthly', 'Quarterly', 'Semesterly', 'Yearly', 'No Aggregation']
const trendTypes = ['Line', 'Bar']

const freqDateFormats = {
    'Weekly': date => moment(date, 'YYYY-MM-DD').format(`Do MMM 'YY`),
    'Monthly': date => moment(date, 'YYYY-MM-DD').format(`MMM 'YY`),
    'Quarterly': date => moment(date, 'YYYY-MM-DD').format(`MMM 'YY`),
    'Semesterly': date => moment(date, 'YYYY-MM-DD').format(`MMM 'YY`),
    'Yearly': date => moment(date, 'YYYY-MM-DD').format(`YYYY`),
    'No Aggregation': date => moment(date, 'YYYY-MM-DD').format(`Do MMM 'YY`)
}

const getDateSeq = (startDate, endDate, frequency) => {
    const frequencies = {
        Monthly: ['01'],
        Quarterly: ['01-01', '04-01', '07-01', '10-01'],
        Semesterly: ['01-01', '07-01'],
        Yearly: ['01-01'],
        Weekly: 'sunday'
    }

    const dates = []
    const start = new Date(startDate)
    const end = new Date(endDate)

    if (!frequencies[frequency]) {
        let currentDate = new Date(start)
        currentDate.setDate(currentDate.getDate() + 1)

        while (currentDate < end) {
            dates.push(currentDate.toLocaleDateString('en-CA'))
            currentDate.setDate(currentDate.getDate() + 1)
        }
    } else {
        const periods = frequencies[frequency]
        let currentYear = start.getFullYear()
        let currentMonth = start.getMonth()

        if (frequency === 'Weekly') {

            let firstSunday = new Date(start)
            firstSunday.setDate(start.getDate() + (7 - start.getDay()) % 7)

            if (firstSunday.toLocaleDateString('en-CA') == start.toLocaleDateString('en-CA')) {
                firstSunday.setDate(firstSunday.getDate() + 7)
            }

            while (firstSunday < end) {
                dates.push(firstSunday.toLocaleDateString('en-CA'))
                firstSunday.setDate(firstSunday.getDate() + 7)
            }
        } else {

            while (new Date(currentYear, currentMonth, 1) < end) {
                for (const period of periods) {
                    let date
                    if (frequency === 'Monthly') {
                        date = new Date(currentYear, currentMonth, 1)
                    } else {
                        date = new Date(`${currentYear}-${period}`)
                    }


                    if (date > start && date < end) {
                        dates.push(date.toLocaleDateString('en-CA'))
                    }
                }

                if (frequency === 'Monthly') {
                    currentMonth++
                    if (currentMonth > 11) {
                        currentMonth = 0
                        currentYear++
                    }
                } else {
                    currentYear++
                }
            }
        }
    }

    return [startDate, ...dates]
}

const aggregate = (data, startDate, endDate, frequency, key, xy, dateFormat) => {

    const rangeData = data.filter(d => d.date >= startDate && d.date <= endDate)
    const key1 = xy ? 'x' : 'date'
    const key2 = xy ? 'y' : (key || 'count')

    if (frequency == 'No Aggregation') {
        const aggregateData = []
        var dataMap = {}
        rangeData.forEach(d => {
            dataMap[d.date] = d[key]
        })

        for (let i = new Date(startDate); i <= (new Date(endDate) || new Date());) {
            var d = {}
            const date = i.toLocaleDateString('en-CA')
            d[key1] = dateFormat ? dateFormat(date) : freqDateFormats[frequency](date)
            d[key2] = dataMap[date] || 0
            aggregateData.push(d)
            i.setDate(i.getDate() + 1)
        }
        return aggregateData
    }

    const rangeDates = getDateSeq(startDate, endDate, frequency)

    return rangeDates.map((_, i) => {
        const key1 = xy ? 'x' : 'date'
        const key2 = xy ? 'y' : (key || 'count')

        var aggregate = {}
        aggregate[key1] = dateFormat ? dateFormat(rangeDates[i]) : freqDateFormats[frequency](rangeDates[i])
        aggregate[key2] = rangeData.filter(d => d.date >= rangeDates[i] && (i == rangeDates.length - 1 ? true : d.date < rangeDates[i + 1])).reduce((sum, d) => sum + d[key || 'count'], 0)

        return aggregate
    })
}

function getPeriod(period) {
    const today = new Date()
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const startOfYear = new Date(today.getFullYear(), 0, 1)
    const startOfQuarter = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1)
    const startOfSemester = new Date(today.getFullYear(), today.getMonth() < 6 ? 0 : 6, 1)

    let startDate, endDate

    switch (period) {
        case 'This Week':
            startDate = new Date(today)
            startDate.setDate(today.getDate() - today.getDay())
            endDate = today
            break
        case 'Last Week':
            startDate = new Date(today)
            startDate.setDate(today.getDate() - today.getDay() - 7)
            endDate = new Date(startDate)
            endDate.setDate(startDate.getDate() + 6)
            break
        case 'This Month':
            startDate = startOfMonth
            endDate = today
            break
        case 'Last Month':
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
            endDate = new Date(today.getFullYear(), today.getMonth(), 0)
            break
        case 'Last 7 days':
            startDate = new Date(today)
            startDate.setDate(today.getDate() - 7)
            endDate = today
            break
        case 'Last 15 days':
            startDate = new Date(today)
            startDate.setDate(today.getDate() - 15)
            endDate = today
            break
        case 'Last 30 days':
            startDate = new Date(today)
            startDate.setDate(today.getDate() - 30)
            endDate = today
            break
        case 'This Quarter':
            startDate = startOfQuarter
            endDate = today
            break
        case 'Last Quarter':
            startDate = new Date(today.getFullYear(), Math.floor((today.getMonth() - 3) / 3) * 3, 1)
            endDate = new Date(startDate)
            endDate.setMonth(startDate.getMonth() + 3)
            endDate.setDate(0)
            break
        case 'This Semester':
            startDate = startOfSemester
            endDate = today
            break
        case 'Last Semester':
            if (today.getMonth() < 6) {
                startDate = new Date(today.getFullYear() - 1, 6, 1)
                endDate = new Date(today.getFullYear() - 1, 11, 31)
            } else {
                startDate = new Date(today.getFullYear(), 0, 1)
                endDate = new Date(today.getFullYear(), 5, 30)
            }
            break
        case 'This Year':
            startDate = startOfYear
            endDate = today
            break
        case 'Last Year':
            startDate = new Date(today.getFullYear() - 1, 0, 1)
            endDate = new Date(today.getFullYear() - 1, 11, 31)
            break
        case 'Till Date':
            startDate = new Date(2021, 8, 26)
            endDate = new Date()
            break
        default:
            throw new Error('Invalid period')
    }

    return [startDate.toLocaleDateString('en-CA'), endDate.toLocaleDateString('en-CA')]
}

const periods = [
    'Last 30 days',
    'Last 7 days',
    'Last 15 days',

    'This Week',
    'Last Week',

    'This Month',
    'Last Month',

    'This Quarter',
    'Last Quarter',

    'This Semester',
    'Last Semester',

    'This Year',
    'Last Year',

    'Till Date'
]

const _ = {
    emailRegex,
    phoneRegex,
    statusColors,
    regularitySeq,
    courseColors,
    progressColors,
    periods,
    aggregationFreqs,
    trendTypes,

    coerceToArray,
    filterAccess,
    getDateSeq,
    aggregate,
    getPeriod
}

export default _