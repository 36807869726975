import "./index.css"
import Icon from "../../icon"
import { useNavigate } from "react-router-dom"
import { useGlobal } from '../../../../context/global'
import Glass from "../../../custom/glass"


const AppMenu = ({ open, close }) => {

    const { auth } = useGlobal()
    const { user } = auth

    const navigate = useNavigate()

    const menus = [
        {
            name: "Home",
            link: "/home",
            icon: "home",
            access: 100
        },
        {
            name: "Users",
            link: "/users",
            icon: "group",
            access: -100
        },
        {
            name: "Community",
            link: "/community",
            icon: "groups",
            access: 10
        },
        {
            name: "Buddies",
            link: "/buddies",
            icon: "group",
            access: [20, 30],
            exact: true,
        },
        {
            name: "Assignees",
            link: "/assignees",
            icon: "group",
            access: 10,
            exact: true
        },
        {
            name: "Gifts",
            link: "/gifts",
            icon: "gift",
            access: [-100, 10, 20],
            exact: true
        }
    ]

    const socialMedia = [
        {
            name: "call",
            link: 'tel:+919945728008'
        },
        {
            name: "whatsapp",
            link: 'https://chat.whatsapp.com/GKGASC7J4v18JdCQJ628t4',
        },
        {
            name: 'instagram',
            link: 'https://www.instagram.com/folkmysore/'
        },
        {
            name: 'facebook',
            link: 'https://www.facebook.com/folkmys',
        },
        {
            name: 'youtube',
            link: 'https://www.youtube.com/channel/UCqkonVPtC6MZ3DWX0WB5ZEA'
        }
    ]

    return (
        <div>
            <div className={`appmenu-slider ${open ? "open" : ""}`}>
                <div className="appmenu-cont">
                    <div className="appmenu-label">MENU</div>
                    <div className="appmenu-items">
                        {
                            menus.filter(m => {
                                return m.exact ? Array.isArray(m.access) ? m.access.indexOf(user.roleIndex) != -1 : m.access == user.roleIndex : m.access >= user.roleIndex
                            }).map(m => {
                                const handleMenuClick = () => {
                                    navigate(m.link)
                                }
                                return (
                                    <div className={`appmenu-item ${window.location.pathname == m.link ? "selected" : ""}`} onClick={handleMenuClick}>
                                        <Icon name={m.icon} color="#fff" size={[5, 1.5]} />
                                        <div className="appmenu-item-name">{m.name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div>
                    <div className="appmenu-divider"/>
                    <div className="appmenu-social">
                        {
                            socialMedia.map(s => <a href={s.link} target="_blank">
                                <Icon
                                    name={s.name}
                                    color="white"
                                    size={[5, 1.5]}
                                />
                            </a>)
                        }
                    </div>
                </div>
            </div>

            {open ? <Glass onClick={close} /> : null}
        </div>
    )
}

export default AppMenu