import "./index.css"
import Icon from '../../../../custom/icon';
import { useCallback, useEffect, useRef, useState } from "react";
import DotLoader from "../../../../info/dotloader"

const Text = props => {
    const { type, min, max, onEditComplete, onChange, onFocusChange, placeholder, post, def, auto, list, listMap, filter, autoDelay } = props

    var [value, setValue] = useState(def || "")
    var [focus, setFocus] = useState()
    var [filteredList, setFilteredList] = useState([])
    var [filtering, setFiltering] = useState(false)

    const timeout = useRef()

    const inputRef = useRef()
    const isPara = type === 'para'

    // post-process value after blurred and forward focus event to parent component
    useEffect(() => {
        if (focus !== undefined) {
            onFocusChange && onFocusChange(focus)
            if (!focus) {
                let v = (post && post(value)) || value
                setValue(v)
                onEditComplete && onEditComplete(v)
            }
        }
    }, [focus])

    useEffect(() => {
        onChange && onChange(value)

        if (auto) {

            setFiltering(true)

            clearTimeout(timeout.current)
            timeout.current = setTimeout(() => {
                const filterFunc = filter ? (v => filter(v, value)) : (v => v.toLowerCase().includes(value.toLowerCase()))
                setFilteredList(value ? list.filter(filterFunc) : [])
                setFiltering(false)
            }, autoDelay || 1000)
        }


    }, [value])

    const handleValueChange = useCallback(e => setValue(e.target.value))

    const handleFocus = () => setFocus(true)
    const handleBlur = () => setFocus(false)

    const clearInput = useCallback(() => {
        setValue("")
        setFocus(true)
        inputRef.current.focus()
    })

    return (
        <div className="texin-root0">
            <div className={`texin-root`}>
                {isPara ?
                    <textarea
                        ref={inputRef}
                        type={type}
                        className={`texin-field`}
                        onChange={handleValueChange}
                        value={value}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        placeholder={placeholder}
                    /> :
                    <input
                        ref={inputRef}
                        type={type}
                        min={min}
                        max={max}
                        className={`texin-field`}
                        onChange={handleValueChange}
                        value={value}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        placeholder={placeholder}
                    />
                }
                {value && <Icon className="texin-close texin-icon" name="close" color="#888" size={4.5} onClick={clearInput} />}
            </div>
            {auto && value ?
                <div className="texin-auto-list" >
                    <div className="texin-search-info">{filtering ? <DotLoader text={"Searching"} /> : `${filteredList.length || "No"} results found`}</div>
                    <div className="flex-col" onClick={clearInput}>
                        {filteredList.map(listMap)}
                    </div>
                </div> : null}
        </div>

    )
}

export default Text