import { useEffect, useState } from "react"
import "./index.css"

const DotLoader = ({text, className}) => {

    const [count, setCount] = useState(0)

    useEffect(()=>{
        setInterval(()=>{
            setCount(count => count+1)
        }, 500)
    }, [])

    return <div className={className}>{`${text}${".".repeat(count%4)}`}</div>
}

export default DotLoader