import './App.css'
import './prototypes.js'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Login from './pages/login/index.jsx'
import Home from './pages/homepage/index.jsx'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import EditUserPhoto from './pages/edit-up/index.jsx'
import Buddies from './pages/buddies/index.jsx'
import Users from './pages/users/index.jsx'
import Install from './pages/install/index.jsx'
import Assignees from './pages/assignees/index.jsx'

import { GlobalProvider } from './context/global/index.js'
import UserAgent from './pages/test/user-agent/index.jsx'
import Community from './pages/community/index.jsx'
import Gifts from './pages/gifts/index.jsx'
import SignUp from './pages/signup/index.jsx'
import Attendance from './pages/attendance/index.jsx'

function App({ storeData, apiClient }) {

  return (
    <div className="App">
      <>
        <GlobalProvider storeData={storeData} apiClient={apiClient}>
          <Router>
            <Routes>
              <Route path="/" exact element={<Login />}></Route>
              <Route path="/login" exact element={<Login />}></Route>
              <Route path="/install" exact element={<Install />}></Route>
              <Route path="/home" exact element={<Home />}></Route>

              <Route path="/community" exact element={<Community />}></Route>
              <Route path="/buddies" exact element={<Buddies />}></Route>
              <Route path="/assignees" exact element={<Assignees />}></Route>
              <Route path="/users" exact element={<Users />}></Route>
              <Route path="/gifts" exact element={<Gifts />}></Route>
              <Route path="/signup" exact element={<SignUp />}></Route>
              <Route path="/attendance" exact element={<Attendance />}></Route>

              <Route path="/edit-up" exact element={<EditUserPhoto />}></Route>

              <Route path="/test/user-agent" exact element={<UserAgent />}></Route>
            </Routes>
          </Router>
        </GlobalProvider>
      </>
      <ToastContainer />
    </div>
  )
}

export default App
