import "./index.css"
import DP from "../../components/custom/dp"
import { useEffect, useRef, useState } from "react"
import Loader from "../../components/info/loader"
import { useGlobal } from "../../context/global"

const Install = ()=>{

    var prepTimer = useRef()
    var prepTimerCount = useRef(0)

    const INIT = 0
    const READY = 1
    const LISTENING = 2
    const TIMEDOUT = 3
    const INSTALLED = 4

    var [ state, setState ] = useState(INIT)

    useEffect(()=>{
        const bc = new BroadcastChannel('install')
        
        bc.addEventListener('message', message=>{
            if(message.data=="ready!"){
                setState(READY)
                setTimeout(()=>bc.postMessage('listen!'), 1000)
            }else if(message.data=="listening!"){
                setState(LISTENING)
            }else if(message.data=="installed!")(
                setState(INSTALLED)
            )
        })

        prepTimer.current = setInterval(()=> {
            bc.postMessage('ready?')
            prepTimerCount.current++
            if(prepTimerCount.current>=30){
                setState(TIMEDOUT)
            }
        }, 1000)
    }, [])

    useEffect(()=>{
        if(state>INIT){
            clearInterval(prepTimer.current)
        }
    }, [state])

    const { deviceInfo } = useGlobal()
    if(deviceInfo.appInstalled){
        window.open('/', '_self')
    }

    return (
        <div className="install-root">
            <div className="install-logo">
                <DP url={'./main.png'} size={50}/>
            </div>
            {(state!=TIMEDOUT || state!=INSTALLED) && <div className="install-loader">
                {state<LISTENING && <Loader delay={30} progress="Checking installation compatability..."/>}
            </div>}
            <div className="install-body">
                {state>=READY && state<=LISTENING && <button id="install-button">{state==READY?'Loading...':'Install App'}</button>}
                {state==TIMEDOUT && 
                    <div className="install-timeout">
                        <div>We were unable to to automatically install the app. But do not worry, below are few suggestions:</div>
                        <ul>
                            <li className="install-timeout-instr">Add this page as home-screen shortcut. You may be able to use this site as an app just by opening the home-screen shortcut</li>
                            <li className="install-timeout-instr">Check if "Install App" option is visible in page-settings, generally found on right-top corner of the browser</li>
                            <li className="install-timeout-instr">If nothing works, you can open this webapp from browser only. Click below to open in browser</li>
                        </ul>
                        <a className="install-in-browser" href="/">Open in browser</a>
                    </div>
                }
                {state==INSTALLED && 
                    <div className="install-installed">
                        <div>FOLK Web-App is being installed on your device now. Please open the app from App-Launcher after installation is complete!</div>
                    </div>
                }
            </div>
        </div>
    )

}

export default Install