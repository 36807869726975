import _ from '../../_'



export const getMemberConfig = () =>
    [
        {
            section: 'Details',
            fields: [
                {
                    title: "Name",
                    name: "name",
                    mandatory: true,
                    post: v => v && v.toNameCase()
                },
                {
                    title: "Phone",
                    name: "phone",
                    type: "tel",
                    mandatory: true,
                    post: v => v && v.toPhoneCase()
                },
                {
                    title: "Email",
                    name: "email",
                    type: 'email',
                    mandatory: true,
                    post: v => v && v.toPhoneCase()
                }
            ]
        }
    ]

export const getDescription = member => {

    switch (member.occupationalStatus) {
        case 'Student':
            return `Studying ${member.course || ""} ${member.institution ? "at " : ""}${member.institution || ""}`.trim()
        case 'Working':
            return `${member.designation || "Working"} ${member.organization ? "at " : ""}${member.organization || ""}`.trim()
        case 'Seeking Job':
            return `Seeking job, studied${member.course ? ` ${member.course} ` : " "}${member.institution ? "from " : ""}${member.institution || ""}`.trim()
        default:
            return 'FOLK Member'
    }
}

export const encode = value => value ? btoa(value) : null
export const decode = value => value ? atob(value) : null

export const getPayload = (payload, formData, update) => {
    getMemberConfig([]).forEach(section => {
        section.fields.forEach(config => {
            if (config.encode) {
                if (!(update && formData[config.name] === undefined)) {
                    payload[config.name] = encode(formData[config.name])
                }
            }
            if (config.drop) {
                delete payload[config.name]
            }
        })
    })
    return payload
}

export const reversePayload = (values) => {
    values = values || {}
    var payload = { ...values }
    getMemberConfig([]).forEach(section => {
        section.fields.forEach(config => {
            if (config.encode) {
                payload[config.name] = decode(values[config.name])
            }
            if (config.drop) {
                payload[config.name] = config.dropValue(values)
            }
            if (config.type == 'bool') {
                payload[config.name] = !!payload[config.name]
            }
        })
    })
    return payload
}
