import DP from "../../../components/custom/dp"
import Icon from "../../../components/custom/icon"
import "./index.css"

export const MemberSearch = ({ member, onClick }) => {
    const { name, phone, courseID } = member

    const handleClick = () => {
        onClick(member)
    }

    return (
        <div onClick={handleClick} className="flex-row flex-space-between flex-align-center att-mem-root">
            <div className="flex-row flex-align-center">
                <DP user={member} size={15} enlarge />
                <div className="flex-col att-mem-data">
                    <div className="att-mem-name bold">{name}</div>
                    <div className="att-mem-phone">{phone}</div>
                </div>
            </div>
            <div>{courseID}</div>
        </div>
    )
}

export const MemberSelect = ({ member, onClear, onSelect }) => {
    const { name, phone, courseID } = member

    return (
        <div className="flex-row flex-space-between flex-align-center att-mem-root att-mem-sel-root">

            <div className="flex-row flex-align-center att-memsel-info">
                <DP user={member} size={15} enlarge />
                <div className="flex-col att-mem-data">
                    <div className="att-mem-name bold">{name}</div>
                    <div className="att-mem-phone">{phone}</div>
                </div>
            </div>

            <div>{courseID}</div>

            <div className="flex-row flex-space-between">
                <Icon className="att-memsel-tick" name="tick" size={7} color="green" onClick={() => onSelect(member)} />
                <Icon className="att-memsel-tick" name="close" size={7} color="red" onClick={onClear} />
            </div>
        </div>
    )
}

export const MemberAttendance = ({ member, onClear, onSelect }) => {
    const { name, phone, courseID } = member

    return (
        <div className="flex-row flex-space-between flex-align-center att-mem-root att-mem-sel-root">

            <div className="flex-row flex-align-center att-memsel-info">
                <DP user={member} size={15} enlarge />
                <div className="flex-col att-mem-data">
                    <div className="att-mem-name bold">{name}</div>
                    <div className="att-mem-phone">{phone}</div>
                </div>
            </div>

            <div>{courseID}</div>

            <div className="flex-row flex-space-between">
                <Icon className="att-memsel-tick" name="tick" size={7} color="green" onClick={() => onSelect(member)} />
                <Icon className="att-memsel-tick" name="close" size={7} color="red" onClick={onClear} />
            </div>
        </div>
    )
}