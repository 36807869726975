import { useEffect, useState } from "react"
import Header from "../../components/custom/header"
import "./index.css"
import { useGlobal } from "../../context/global"
import moment from "moment"
import Icon from "../../components/custom/icon"
import Input from "../../components/custom/input"
import { MemberSearch, MemberSelect, MemberAttendance } from "./member"
import DotLoader from "../../components/info/dotloader"
import ListView from "../../components/holders/listView"
import Bubble from "../../components/holders/bubble"

const Attendance = props => {

    const { api } = useGlobal()
    const [date, setDate] = useState()
    const [dates, setDates] = useState()
    const [events, setEvents] = useState()
    const [members, setMembers] = useState()
    const [selectedMember, setSelectedMember] = useState()
    const [attendance, setAttendance] = useState()

    useEffect(() => {
        api.call('/get-attendance-dates').then(res => {
            const d = res.data.map(d => d.startDate)
            setDates(d)
            const date = d[0]
            setDate(date)

            api.call('/get-attendance', { date }).then(res => {
                setAttendance(res.data)
            }).catch(err => {
                console.log(err)
            })

            api.call('/get-attendance-data', { date }).then(res => {
                const events = res.data[0]
                const members = res.data[1]
                setEvents(events)
                setMembers(members)
            }).catch(err => {
                console.log(err)
            })

        }).catch(err => {
            console.log(err)
        })
    }, [])

    const handleMemberSelect = member => {
        member.courseID = member.courseID || "SOS"
        setSelectedMember(member)
    }

    const memberListMap = member => <MemberSearch member={member} key={member.id} onClick={handleMemberSelect} />

    const filterFunc = (mem, searchKey) => {
        const isName = isNaN(searchKey)
        const memberKey = mem[isName ? 'name' : 'phone'].toLowerCase().replace(/\s+/g, "")
        searchKey = searchKey.toLowerCase().replace(/\s+/g, "")
        return memberKey.includes(searchKey)
    }

    const clearSelectedMember = () => {
        setSelectedMember(null)
    }

    const markSelectedMember = member => {
        const selectedEvent = events.filter(event => event.courseID == member.courseID)[0]

        if (selectedEvent) {

            const participantID = member.phone
            const eventID = selectedEvent.id
            const phone = participantID
            const time = moment().format("HH:mm")
            const attendance = 1
            const remarks = ""
            const name = member.name

            const payload = { participantID, eventID, phone, time, attendance, remarks, name }

            api.call('/update-attendance', { date, payload }).then(res => {
                setSelectedMember(null)
                setAttendance(res.data)
            }).catch(err => {
                alert(`${err}`)
            })
        } else {
            alert(`No event found for the courseID ${member.courseID}`)
        }
    }

    const attendanceMap = member => <MemberAttendance member={member} />

    return (
        <div className="att-root">
            <Header title={"Attendance"} />

            {true && <Bubble dark>
                {selectedMember ?
                    <div className="att-memsel">
                        <MemberSelect member={selectedMember} onSelect={markSelectedMember} onClear={clearSelectedMember} />
                    </div>
                    : null
                }
            </Bubble>}

            <div className="att-body">
                <div className="att-date-cont">
                    {date ?
                        <>
                            <div className="att-date">{`Attendance on ${moment(date, "YYYY-MM-DD").format("Do MMMM YYYY")}`}</div>
                            <Icon className="att-edit-date" name="edit" size={4.5} color="#555" />
                        </> :
                        <DotLoader text="Loading" />
                    }
                </div>

                <div className="att-search-cont">
                    <div className="att-search">
                        <Input
                            placeholder="Search name or phone..."
                            list={members}
                            listMap={memberListMap}
                            filter={filterFunc}
                            auto
                        />
                    </div>
                </div>


                {
                    attendance ?
                        <div>
                            <ListView list={attendance} map={attendanceMap} />
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default Attendance