import "./index.css"

import { Bar, Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, ChartDataLabels)

export const TrendGraph = ({ config, className, type }) => {

    const { data, options } = config

    return <div className={className}>
        {type == 'Line' ? <Line data={data} options={options} /> :
            <Bar data={data} options={options} />}
    </div>
}

export const getTrendGraphConfig = (dashboardConfig, graphData) => {

    return (
        {
            data: {
                datasets: graphData.map(d => {
                    const id = d.courseID || d.milestoneID
                    return {
                        label: id,
                        data: d.data,
                        backgroundColor: dashboardConfig.colors[id],
                        borderColor: dashboardConfig.colors[id],
                        pointRadius: .5,
                        pointHoverRadius: 5,
                        borderWidth: 2,
                        tension: 0.4
                    }
                })
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: dashboardConfig.title.toTitleCase(),
                        position: 'top',
                    },
                    datalabels: graphData[0] && graphData[0].data && graphData[0].data.length < 20 ? {
                        anchor: 'end',
                        align: 'top',
                        color: 'grey',
                        font: {
                            size: 12
                        },
                        formatter: (value) => value.y
                    } : null
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                }
            }
        }
    )
}
